* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
}

.main_div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    padding: 10px;
}

.login_container {
    background: white;
    padding: 40px 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
}

.logo img {
    width: 50px;
    margin-bottom: 20px;
}

.title {
    font-size: 18px;
    color: #4e5b96;
    margin-bottom: 20px;
}

.input_group {
    margin-bottom: 20px;
}

.input_group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.show_password {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.show_password input {
    margin-right: 10px;
}

.forgot_password {
    margin-bottom: 20px;
}

.forgot_password a {
    color: #4e5b96;
    text-decoration: none;
}

.sign_in_btn {
    background: #4e5b96;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
}

.sign_in_btn:hover {
    background: #3b4a80;
}

@media (max-width: 768px) {
    .login_container {
        padding: 30px 20px;
    }

    .title {
        font-size: 16px;
    }

    .input_group input {
        padding: 8px;
        font-size: 13px;
    }

    .sign_in_btn {
        padding: 8px 15px;
        font-size: 13px;
    }
}

@media (max-width: 480px) {
    .login_container {
        padding: 20px 15px;
    }

    .title {
        font-size: 14px;
    }

    .input_group input {
        padding: 7px;
        font-size: 12px;
    }

    .sign_in_btn {
        padding: 7px 10px;
        font-size: 12px;
    }
}