.report-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    background-color: #f7f8fc;
}

.report-card {
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.report-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.report-title {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    color: #333;
}

.export_btn {
    margin-left: 10px;
    border: none;
    padding: 10px 20px;
    background-color: #0a4f88;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.export_btn:hover {
    background-color: #084075;
}

.report-header h3 {
    font-size: 18px;
    margin: 0;
}

.report-card p {
    flex-grow: 1;
    font-size: 14px;
    color: #555;
}

.proceed-button {
    background-color: transparent;
    color: #0a4f88;
    border: none;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;
}

.proceed-button i {
    margin-left: 5px;
    font-size: 12px;
}

.proceed-button:hover {
    color: #0a4f88;
}

/* report start */
.enrollment-report-container {
    font-family: Arial, sans-serif;
    max-width: 1200px;
}

.report-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
    color: #333;
}

.report-table {
    background-color: #fff;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.report-table table {
    width: 100%;
    border-collapse: collapse;
}

.report-table th,
.report-table td {
    padding: 5px;
    text-align: left;
    border-right: 1px solid #ddd;
    /* Add a right border to columns */
}

.report-table th {
    background-color: #0a4f88;
    /* Your theme color */
    color: white;
    font-weight: 600;
    /* Semi-bold */
    font-size: 14px;
    letter-spacing: normal;
    /* Normal letter spacing */
    text-transform: none;
    /* No uppercase transformation */
    border-right: 1px solid #ccc;
    /* Add a right border to headers with a slightly different color */
}

.report-table tbody tr {
    border-bottom: 1px solid #ddd;
    transition: background-color 0.2s ease;
}

.report-table tbody tr:last-child {
    border-bottom: none;
}

.report-table tbody tr:hover {
    background-color: #f5f5f5;
}

.report-table td {
    font-size: 14px;
    color: #555;
}

.report-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* General container styling */
.report-container {
    padding: 5px;
    background-color: #f7f8fc;
}

/* Table styling for larger screens */
.report-table-wrapper {
    display: none;
    /* Hidden on mobile by default */
    background-color: #fff;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
    /* Added margin to separate the table from the boxes */
}

.report-table {
    width: 100%;
    border-collapse: collapse;
}

.report-table th,
.report-table td {
    padding: 12px 15px;
    text-align: left;
    border-right: 1px solid #ddd;
}

.report-table th {
    background-color: #0a4f88;
    color: white;
}

.report-table tbody tr {
    border-bottom: 1px solid #ddd;
    transition: background-color 0.2s ease;
}

.report-table tbody tr:hover {
    background-color: #f5f5f5;
}

/* Card styling for mobile view */
.report-cards {
    display: none;
    /* Hidden on larger screens */
}

.report-card {
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.report-card strong {
    color: #0a4f88;
    /* Your theme color for headings */
    margin-bottom: 5px;
}

.report-card div {
    margin-bottom: 10px;
    font-size: 14px;
    color: #555;
}
.report-cards_rep{
    display: none;
}
/* Media query for larger screens */
@media (min-width: 769px) {
    .report-table-wrapper {
        display: block;
        /* Show table on screens wider than 768px */
    }

    .report-cards {
        display: none;
        /* Hide cards on larger screens */
    }
}

/* Media query for mobile screens */
@media (max-width: 886px) {
    .report-table-wrapper {
        display: none;
        /* Hide table on screens smaller than 769px */
    }

    .report-cards_rep{
        display: block;
        /* Show cards on screens smaller than 769px */
    }
}
.appr_btn{
    background: green;
    border: none;
    padding: 5px;
    border-radius: 3px;
    color: white;
    font-weight: bold;
}
.right-panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background-color: #f4f4f4;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.3);
    padding: 20px;
    transition: transform 0.3s ease;
}
.menu-icon {
    cursor: pointer;
    font-size: 20px;
    margin-left: 20px;
}
