.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #0a4f88;
    color: #fff;
    padding: 10px;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.top-bar-left {
    display: flex;
    align-items: center;
}

.top-bar-logo {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.top-bar-logo img {
    height: 40px;
    margin-right: 10px;
}

.menu-icon {
    cursor: pointer;
    display: block;
    margin-left: 20px;
}

.content-wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
    margin-top: 60px;
}

.sidebar {
    background-color: #013661;
    color: #fff;
    min-width: 250px;
    max-width: 250px;
    transition: all 0.3s ease;
}

.sidebar.closed {
    min-width: 60px;
    max-width: 60px;
}

.sidebar-menu {
    list-style-type: none;
    padding: 0;
}

.sidebar-menu a {
    text-decoration: none;
}

.sidebar-menu li {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    color: white;
}

.sidebar-menu li:hover {
    background-color: #0a4f88;
}

.sidebar-menu li .menu-text {
    margin-left: 10px;
    transition: opacity 0.3s ease;
}

.sidebar.closed .menu-text {
    opacity: 0;
}

.smain-content {
    flex: 1;
    overflow-y: auto;
    transition: all 0.3s ease;
    padding: 10px;
}

/* User Profile CSS */
.profile-pic {
    background-color: transparent;
    /* Ensure the background is transparent */
    border-radius: 50%;
    /* This makes the image round */
    width: 40px;
    height: 40px;
    object-fit: cover;
    /* This ensures the image covers the area while maintaining aspect ratio */
}

.user-profile {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user-icon {
    margin-right: 30px;
    width: 50px;
    height: 40px;
}

.user-menu {
    position: absolute;
    top: 50px;
    right: 10px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    z-index: 1000;
    width: 300px;
}

.user-info {
    padding: 10px;
    background-color: #fff;
    /* Changed from #f0f0f0 to #fff */
    font-weight: bold;
    color: #0a4f88;
    border-bottom: 1px solid #ddd;
}

.user-id,
.user-role {
    display: block;
    font-size: 14px;
}

.menu-options {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu-options a {
    text-decoration: none;
}

.menu-options li {
    padding: 10px 20px;
    color: gray;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.menu-options li:hover {
    background-color: #f0f0f0;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
    .sidebar {
        position: fixed;
        height: 100%;
        z-index: 9;
        transform: translateX(-100%);
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .content-wrapper {
        margin-top: 60px;
        margin-left: 0;
    }

    .smain-content {
        margin-left: 0;
    }
}